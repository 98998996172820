export default function checkRules (formData) {
  const checkPassword = (rule, value, callback) => { // 校验两次密码是否一致
    if (!value) {
      callback(new Error('请再次输入密码'))
    }
    if (formData.newPassword !== value) {
      callback(new Error('两次输入密码不一致'))
    }
    callback()
  }
  const formRules = { // 表单校验规则
    oldPassword: [
      {
        required: true,
        message: '请输入原密码',
        trigger: 'change'
      }
    ],
    newPassword: [
      {
        required: true,
        message: '请输入新密码',
        trigger: 'change'
      }
    ],
    verifyPassword: [{ validator: checkPassword, trigger: 'change', required: true }]
  }
  return formRules
}
