import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { ElMessage } from 'element-plus';
import { reactive, ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import checkRules from './formRules';
export default {
  setup: function setup() {
    var $api = inject('$api');
    var router = useRouter();
    var store = useStore();
    var isLoading = ref(false); // 点击登录按钮，增加loading效果，防止连续快速多次点击

    var ruleFormRef = ref(null); // 设置表单DOM

    var ruleForm = reactive({
      // 设置表单字段
      oldPassword: '',
      newPassword: '',
      verifyPassword: ''
    });
    var rules = reactive(checkRules(ruleForm)); // 设置表单校验规则

    var updatePassword = function updatePassword() {
      // 修改密码
      isLoading.value = true;
      $api.updatePassword(ruleForm).then(function (data) {
        store.commit('user/updateUserInfo', {});
        store.commit('device/updateDeviceList', []);
        ElMessage({
          message: '修改成功，请重新登录',
          type: 'success'
        });
        router.replace({
          path: '/login'
        });
      }).catch(function () {
        isLoading.value = false;
      });
    };

    var submitForm = function submitForm(formEl) {
      // 表单校验
      formEl.validate(function (valid) {
        if (valid) updatePassword(); // else ElMessage({ message: '请先完整填写表单后再提交', type: 'error' })
      });
    };

    return {
      ruleForm: ruleForm,
      rules: rules,
      submitForm: submitForm,
      ruleFormRef: ruleFormRef,
      isLoading: isLoading
    };
  }
};